<template>
 <div>
        <div class="header">
            <div class="header-text">
             <h2>XE快速、高效设计模板</h2>
             <p>0门槛操作-简单拖拽即可完成</p>
            </div>
    </div>
    <div class="body">
        <div class="about">
           <h2>关于宣易</h2>
           <div class="about-content">
               <img src="@/assets/img/about-right.png" alt="">
               <p>
                  汕头市轩逸科技有限公司是一家互联网、新媒体公司，公司人员由汕头本地从业多年的专业人员组成，涉及到软件研发，小程序研发，新媒体创作，互联网营销方案策划等业务。公司新媒体部门（由方案专员、插画师、摄影师、平面设计师组成）致力于为商家、企业提供包括营销工具，营销内容（海报、H5设计、表单制作），线上营销方案全方位服务。
                  <br />
                  <br />
                  “宣易平台”为商家、企业制作海报、H5，成功案例有500+个，为商家、企业带来超预期的线上营销效果。
               </p>
           </div>
        </div>
        <div class="product-contain">
        <div class="product">
             <h2>宣易XEditor</h2>
         <div class="product-content">
          <div class="H5">
               <img src="@/assets/img/H5.png" alt="">
           <div class="product-text">
                 <h4> H5</h4>
              <span>在线快速创作</span>
           </div>
          </div>
            <div class="HB">
                <img src="@/assets/img/HB.png" alt="">
           <div class="product-text">
                 <h4> 海报</h4>
           <span>轻松便捷作图</span>
           </div>
          </div>
           <div class="Form">
                <img src="@/assets/img/form.png" alt="">
             <div class="product-text">
                  <h4>表单</h4>
              <span>问卷表单工具</span>
             </div>
          </div>
         </div>
        </div>
        </div>
        <div class="contact">
           <h2>联系我们</h2>
           <div class="contact-box">
               <div class="contact-content">
                  <div class="contact-us">
                      <div class="contact-box">
                      <h4>    <a-icon type="environment" />地址:广东省汕头 龙湖区龙光阳光雅轩(西1门)2 栋 301</h4>
                  </div>
                    <div class="contact-box">
                        <h4><a-icon type="phone" />客服电话：0754-8638 5586</h4>
                    </div>
                     <div class="contact-box">
                       <h4>  <a-icon type="mail" />商务邮箱：xuanyixeformkefu@xuan1.net</h4>
                    </div>
                    <div class="contact-box">
                   <h4>     <i class="iconfont icona-QQ1" />商务合作：3198712623</h4>
                    </div>
                    <div class="code">
                       <img src="@/assets/img/宣易二维码.png" alt="">
                       <p>关注“宣易XEditor”公众号</p>
                    </div>
                  </div>
               </div>
                <!-- <div class="contact-img">
                </div> -->
                 <div style="width:530px;height:450px;border:#ccc solid 1px;font-size:12px" id="container"></div>
           </div>
        </div>
    </div>
 </div>
</template>
<script>
import { BMPGL } from './bmpgl'
export default {
    data(){
        return{
            ak:'9xEGPpRmN8NApxSlps6Xqs7LEws1cMef'
        }
    },
    methods:{
        initMap() {
      //传入密钥获取地图回调
BMPGL(this.ak).then((BMapGL) => {
// 创建地图实例
let map = new BMapGL.Map("container");
// 创建点坐标 axios => res 获取的初始化定位坐标
let point = new BMapGL.Point(116.74476, 23.36915)
// 初始化地图，设置中心点坐标和地图级别
map.centerAndZoom(point, 19)
//开启鼠标滚轮缩放
map.enableScrollWheelZoom(true)
// eslint-disable-next-line no-undef
var marker = new BMapGL.Marker(point)// 创建标注
map.addOverlay(marker);
// map.setHeading(64.5)
// map.setTilt(73)
// 保存数据
// this.myMap = map
})
.catch((err)=>{
console.log(err)
})
    },
    },
        mounted(){
             /* eslint-disable */
   this.initMap()
        }
}
</script>

<style lang="less" scoped>
.header{
    background: url('../../assets/img/banner.png') no-repeat;
    height: 278px;
    min-width: 1180px;
    position: relative;
    .header-text{
        width: 1180px;
        height: 278px;
        margin: 0 auto;
        padding: 80px 0;
        h2{
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        p{
            font-size: 24px;
        }
    }
}
.body{
    margin-top: 40px;
    min-width: 1180px;
    .about{
        width: 1180px;
        margin: 0 auto;
        h2{
            font-size: 36px;
            color: #0454ff;
               font-weight: 700;
        }
        .about-content{
            display: flex;
            margin-top: 40px;
            img{
                width: 381px;
                height: 221px;
            }
            p{
                font-size: 16px;
                color: #333;
                width: 650px;
                margin-left: 50px;
                text-align: left;
            }
        }
    }
   .product-contain{
       background-color: #fff;
       margin: 75px 0;
       padding: 45px 0 75px 0;

        .product{
        width: 1180px;
        margin: 0 auto;
         h2{
          font-size: 36px;
            color: #0454ff;
               font-weight: 700;
        }
        .product-content{
               display: flex;
            margin-top: 40px;
            .product-text{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    font-size: 16px;
                    color: #999;
                }
            }
            h4{
                font-size: 36px;
                font-weight: 700;
                margin-top: 10px;
                color: #333;
            }
            .H5{
                width: 33%;
            }
            .HB{
                width: 33%;
                margin: 0 40px;
            }
            .Form{
                width: 33%;
            }
            img{
                width: 100%;
                border-radius: 0;
            }
        }
    }
   }
    .contact{
          width: 1180px;
        margin: 0 auto;
        h2{
    font-size: 36px;
            color: #0454ff;
               font-weight: 700;
        }
        .contact-box{
            display: flex;
            justify-content: space-between;
            margin: 30px 0;
            .contact-content{
               .contact-us{
                   p{
                       margin-bottom: 5px;
                   }
                   h4{
                         font-size: 16px;
                       i{
                           margin-right: 15px;
                           color: #0f5bff;
                       }
                   }
                   .contact-box{
                       margin: 0 0 15px 0;
                   }
                   .code{
                       width: 188px;
                       height: 188px;
                       text-align: center;
                       font-size: 14px;
                       font-weight: 700;
                       margin-top: 52px;
                       img{
                           width: 100%;
                           height: 100%;
                           margin-bottom: 10px;
                       }
                   }
               }

            }
             .contact-img{
                 width: 530px;
                 height: 450px;
                 background: url(../../assets/img/location.jpg) no-repeat;
                 background-position: center center;
                background-size: cover;
             }
        }
    }
}
</style>